import React from 'react';
import {  Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

import Buttons from '../../components/Buttons';
import IndicatorDots from '../../components/IndicatorDots';
import Carousel from 're-carousel'

class Home extends React.Component {
    render() {
        return (
            <div class="position-relative overflow-hidden bg-light">
                <div class="mx-auto  row mb-3 py-2 bg-home-title">
                    <div class="p-lg-5 m-2 w-100">
                        <h1 class=""><FormattedMessage id="app.app_name" /></h1>
                        <p class="lead font-weight-normal"><FormattedMessage id="header.home_header" /></p>
                    </div>
                </div>
                <div class="mx-auto container row my-3">
                    <div class="col-12 col-sm-4 col-md-4 p-2 container">
                        <Link to="/Translation" replace>
                            <img class="overlay-img image-fluid w-100 max-height-200 img-cover" src={"/asset/home/service1.jpg"} alt="service1" />
                            <div class="overlay">
                                <div class="image-text h6"><FormattedMessage id="app.header_services1" /></div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4 p-2 container">
                        <Link to="/Copywriting" replace>
                            <img class="overlay-img image-fluid w-100 h-100 max-height-200 img-cover" src={"/asset/home/service2.jpg"} alt="service2" />
                            <div class="overlay">
                                <div class="image-text h6"><FormattedMessage id="app.header_services2" /></div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4 p-2 container">
                        <Link to="/Social/Media/Marketing" replace>
                            <img class="overlay-img image-fluid w-100 h-100 max-height-200 img-cover" src={"/asset/home/service3.jpg"} alt="service3" />
                            <div class="overlay">
                                <div class="image-text h6"><FormattedMessage id="app.header_services3" /></div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div class="mx-auto container my-3 max-height-400 max-height-sm-800 " >
                   
                    <Carousel loop auto widgets={[IndicatorDots, Buttons]} >
                        <div>
                            <img class="d-block h-100 w-100 img-fluid max-height-400 img-cover p-2" src={"/asset/home/slide1.jpg"} alt="slide1" />
                            <div class="overlay-carousel-text">
                                <p class="mt-4 h-100 h6"><FormattedMessage id="header.home_slider_1" /></p>
                                <p class="mb-4 h-100 h6"><FormattedMessage id="header.home_slider_1_info" /></p>
                            </div>
                        </div>
                        <div>
                            <img class="d-block h-100 w-100 img-fluid max-height-400 img-cover p-2" src={"/asset/home/slide2.jpg"} alt="slide2" />
                            <div class="overlay-carousel-text">
                                <p class="m-4 h-100 h6"><FormattedMessage id="header.home_slider_2" /></p>
                            </div>
                        </div>
                        <div>
                            <img class="d-block h-100 w-100 img-fluid max-height-400 img-cover p-2" src={"/asset/home/slide3.jpg"} alt="slide3" />
                            <div class="overlay-carousel-text">
                                <p class="m-4 h-100 h6"><FormattedMessage id="header.home_slider_3" /></p>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div class="mx-auto container my-3">
                    <div class="h5 mt-4 my-2 text-left p-2"><FormattedMessage id="header.home_header_2" /></div>
                    <div class="text-left mt-4 mb-5 p-2">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 mb-0">
                                <ul class="list-unstyled mb-0">
                                    <li><div ><i class="fa fa-fw fa-hand-holding-usd"></i> <FormattedMessage id="header.home_list_1_item_1" /></div></li>
                                    <li><div ><i class="fa fa-fw fa-bitcoin"></i> <FormattedMessage id="header.home_list_1_item_2" /></div></li>
                                    <li><div ><i class="fa fa-fw fa-cogs"></i> <FormattedMessage id="header.home_list_1_item_3" /></div></li>
                                    <li><div ><i class="fa fa-fw fa-desktop"></i> <FormattedMessage id="header.home_list_1_item_4" /></div></li>
                                    <li><div ><i class="fa fa-fw fa-shopping-cart"></i> <FormattedMessage id="header.home_list_1_item_5" /></div></li>
                                </ul>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 mt-0">
                                <ul class="list-unstyled mt-0">
                                    <li><div ><i class="fa fa-fw fa-ad"></i> <FormattedMessage id="header.home_list_1_item_6" /></div></li>
                                    <li><div ><i class="fa fa-fw fa-stethoscope"></i> <FormattedMessage id="header.home_list_1_item_7" /></div></li>
                                    <li><div ><i class="fa fa-fw fa-book-open"></i> <FormattedMessage id="header.home_list_1_item_8" /></div></li>
                                    <li><div ><i class="fa fa-fw fa-theater-masks"></i> <FormattedMessage id="header.home_list_1_item_9" /></div></li>
                                 </ul>
                            </div>
                        </div>

                    </div>
                </div>

{/* 
                <div class="mx-auto container m-4 mt-5">
                    <div class="h5 mt-4 my-2 text-left"><FormattedMessage id="header.home_header_3" /></div>
                    <div class="row">
                        <div class="col-6 col-sm-2 col-md-2">
                            <img class="overlay-img image-fluid w-100 h-100 img-scale-down" src={"/asset/home/client1.jpg"} alt="client1" />
                        </div>
                        <div class="col-6 col-sm-2 col-md-2">
                            <img class="overlay-img image-fluid w-100 h-100 img-scale-down" src={"/asset/home/client2.png"} alt="client2" />
                        </div>
                        <div class="col-6 col-sm-2 col-md-2">
                            <img class="overlay-img image-fluid w-100 h-100 img-scale-down" src={"/asset/home/client3.png"} alt="client3" />
                        </div>

                    </div>
                </div> */}

            </div>
        )
    }

}
export default Home;