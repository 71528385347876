import React from 'react';
import { FormattedMessage } from 'react-intl';

class JoinUs extends React.Component {
    render() {
        return (
            <div class="">
                <div class="position-relative">
                    <img class="w-100 vh-height-100 opacity-30 img-cover" src={"/asset/jointus-poster.jpg"} alt="poster" />
                    <div class="centered">
                        <h4 class=" text-black"> <FormattedMessage id="joinus.home_header" /></h4>
                        <a class="btn btn-dark mt-5 p-4" href="https://linktr.ee/phoenixgate_sol" target="_blank">
                            <h5><FormattedMessage id="joinus.home_btn_5" /> <i class="fa fa-fw fa-arrow-right"></i> </h5>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
export default JoinUs;