import React from 'react';
import { FormattedMessage } from 'react-intl';

class Translation extends React.Component {
    render() {
        return (
            <div class="">
                <div class="mb-4 position-relative">
                    <img class="w-100 max-height-300 opacity-50 img-cover" src={"/asset/translation/poster.jpg"} alt="poster" />
                    <h4 class="centered text-black"> <FormattedMessage id="translation.home_header_1" /><br />-- <FormattedMessage id="translation.home_header_1_1" /></h4>
                </div>
                <div class="container mt-4 text-left">
                    <h5 class="mb-4 font-weight-bold" ><FormattedMessage id="translation.home_title" /></h5>
                    <p><FormattedMessage id="translation.home_header_2" /></p>

                    <hr />
                    <h5 class="my-4 font-weight-bold"><FormattedMessage id="translation.home_header_3" /></h5>
                    <div class="row">
                        <div class="col-12 col-sm-2 col-md-2 pb-2">
                            <img class="float-left mr-4 img-fluid max-height-100 w-100 img-cover" src={"/asset/translation/translation2.jpg"} alt="translation2" />
                        </div>
                        <div class="col-12 col-sm-10 col-md-10">
                            <div class="font-weight-bold my-2"><FormattedMessage id="translation.home_list_1_item_1" /></div>
                            <p><FormattedMessage id="translation.home_list_1_item_1_1" /></p>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-12 col-sm-2 col-md-2 pb-2">
                            <img class="float-left mr-4 img-fluid max-height-100 w-100 img-cover" src={"/asset/translation/translation3.jpg"} alt="translation3" />
                        </div>
                        <div class="col-12 col-sm-10 col-md-10">
                            <div class="font-weight-bold my-2"><FormattedMessage id="translation.home_list_1_item_2" /></div>
                            <p><FormattedMessage id="translation.home_list_1_item_2_1" /></p>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-12 col-sm-2 col-md-2 pb-2">
                            <img class="float-left mr-4 img-fluid max-height-100 w-100 img-cover" src={"/asset/translation/translation1.jpg"} alt="translation1" />
                        </div>
                        <div class="col-12 col-sm-10 col-md-10">
                            <div class="font-weight-bold my-2"><FormattedMessage id="translation.home_list_1_item_3" /></div>
                            <p><FormattedMessage id="translation.home_list_1_item_3_1" /></p>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-12 col-sm-2 col-md-2 pb-2">
                            <img class="float-left mr-4 img-fluid max-height-100 w-100 img-cover" src={"/asset/translation/translation4.jpg"} alt="translation4" />
                        </div>
                        <div class="col-12 col-sm-10 col-md-10">
                            <div class="font-weight-bold my-2"><FormattedMessage id="translation.home_list_1_item_4" /></div>
                            <p><FormattedMessage id="translation.home_list_1_item_4_1" /></p>
                        </div>
                    </div>
                    <hr />
                    <h5 class="my-4 font-weight-bold"><FormattedMessage id="translation.home_header_4" /></h5>
                    <div class="row mb-5">
                    <div class="col-12 col-sm-6 col-md-6 mb-0">
                            <ul class="list-unstyled mb-0">
                                <li><div ><i class="fa fa-fw fa-file-alt"></i> <FormattedMessage id="translation.home_list_2_item_1" /></div></li>
                                <li><div ><i class="fa fa-fw fa-wrench"></i> <FormattedMessage id="translation.home_list_2_item_2" /></div></li>
                                <li><div ><i class="fa fa-fw fa-clinic-medical"></i> <FormattedMessage id="translation.home_list_2_item_3" /></div></li>
                                <li><div ><i class="fa fa-fw fa-network-wired"></i> <FormattedMessage id="translation.home_list_2_item_4" /></div></li>
                                <li><div ><i class="fa fa-fw fa-coins"></i> <FormattedMessage id="translation.home_list_2_item_5" /></div></li>
                                <li><div ><i class="fa fa-fw fa-award"></i> <FormattedMessage id="translation.home_list_2_item_6" /></div></li>
                            </ul>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 mt-0">
                            <ul class="list-unstyled mt-0">
                                <li><div ><i class="fa fa-fw fa-hand-sparkles"></i> <FormattedMessage id="translation.home_list_2_item_7" /></div></li>
                                <li><div ><i class="fa fa-fw fa-closed-captioning"></i> <FormattedMessage id="translation.home_list_2_item_8" /></div></li>
                                <li><div ><i class="fa fa-fw fa-bookmark"></i> <FormattedMessage id="translation.home_list_2_item_9" /></div></li>
                                <li><div ><i class="fa fa-fw fa-dragon"></i> <FormattedMessage id="translation.home_list_2_item_10" /></div></li>
                                <li><div ><i class="fa fa-fw fa-robot"></i> <FormattedMessage id="translation.home_list_2_item_11" /></div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Translation;