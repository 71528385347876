import React from 'react';
import { FormattedMessage } from 'react-intl';

class SocialMediaMarketing extends React.Component {
    render() {
        return (
            <div class="">
                <div class="position-relative">
                    <img class="w-100 max-height-300 opacity-50 img-cover" src={"/asset/marketing/poster.jpg"} alt="poster" />
                    <h4 class="centered text-black"> <FormattedMessage id="app.header_services3" /></h4>
                </div>
                <div class="container mt-4 text-left">
                   <h4 class="text-center text-black"><FormattedMessage id="social_media_marketing.home_header" />...</h4>
                   </div>
            </div>
        )
    }
}
export default SocialMediaMarketing;