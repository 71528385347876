import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

import './App.css';

import { Context } from "./components/Wrapper";
import Home from './pages/Home';
import JoinUs from './pages/JoinUs';
import Translation from './pages/Translation';
import Copywriting from './pages/Copywriting';
import SocialMediaMarketing from './pages/SocialMediaMarketing';
import Contact from './pages/Contact';

function App() {
  const context = useContext(Context);
  return (
    <Router >
      <div className="App">
      <div className="bg-color2"><nav class="navbar navbar-expand-lg navbar-light bg-color2 container">
          <a class="navbar-brand"><img class="d-block mx-auto my-auto" width="40" height="40" src={"/asset/logo.png"} /></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
              <li class="nav-item active">
                <Link class="nav-link" to="/"><FormattedMessage id="app.header_home" /></Link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <FormattedMessage id="app.header_services" />
                </a>
                <div class="dropdown-menu bg-color2" aria-labelledby="navbarDropdownMenuLink">
                  <Link class="dropdown-item d-md-inline-block" to="/Translation"><FormattedMessage id="app.header_services1" /></Link>
                  <Link class="dropdown-item d-md-inline-block" to="/Copywriting"><FormattedMessage id="app.header_services2" /></Link>
                  <Link class="dropdown-item d-md-inline-block" to="/Social/Media/Marketing"><FormattedMessage id="app.header_services3" /></Link>
                </div>
              </li>
              
              <li class="nav-item">
                <Link class="nav-link" to="/Contact"><FormattedMessage id="app.header_contact" /></Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/Join/Us"><FormattedMessage id="app.header_join_us" /></Link>
              </li>
              <li class="nav-item">
              
              </li>
            </ul>
             <select class="nav-link text-center d-inline bg-color2 border-0 text-black font-weight-500" value={context.locale} onChange={context.selectLanguage}>
                <option value="en" class=" text-black font-weight-500">English</option>
                <option value="zh" class=" text-black font-weight-500">中文</option>
              </select>
          </div>
        </nav>
        </div>
        <div class="content">
          <Route exact path="/" component={Home} />
          <Route path="/Home" component={Home} />
          <Route path="/Translation" component={Translation} />
          <Route path="/Copywriting" component={Copywriting} />
          <Route path="/Social/Media/Marketing" component={SocialMediaMarketing} />
          <Route path="/Contact" component={Contact} />
          <Route path="/Join/Us" component={JoinUs} />
        </div>
      </div>
    </Router>

  );
}

export default App;