import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Chinese from '../lang/zh.json';
import English from '../lang/en.json';

export const Context = React.createContext();


const Wrapper = (props) => {

    var local = navigator.language;
    var local_persist = localStorage.getItem('persisted_local');
    if (local_persist !== undefined) {
        local = JSON.parse(local_persist);
    }

    let lang;

    if (local === 'zh') {
        lang = Chinese;
    } else {
        lang = English;
    }

    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === 'zh') {
            setMessages(Chinese);
        } else {
            setMessages(English);
        }
        localStorage.setItem('persisted_local', JSON.stringify(newLocale));
    }

    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>

    );
}


export default Wrapper;