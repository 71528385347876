import React from 'react';
import { FormattedMessage } from 'react-intl';

class Contact extends React.Component {
    render() {
        return (
            <div class="">
                <div class="position-relative">
                    <img class="w-100 max-height-300 opacity-30 img-cover" src={"/asset/contactus-poster.jpg"} alt="poster" />
                    <h4 class="centered text-black"> <FormattedMessage id="contact.home_header_1" /> <br /><FormattedMessage id="contact.home_header_2" /></h4>
                </div>
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="card border-0 mb-5">
                                <div class="card-body p-0">
                                    <div class="row py-2">
                                        <div class="col-4"><div class="h6 text-right"><FormattedMessage id="contact.home_item_1" /></div></div>
                                        <div class="col-8"><div class="h6 text-left">+60 11-5869 9545</div></div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-4"><div class="h6 text-right"><FormattedMessage id="contact.home_item_2" /></div></div>
                                        <div class="col-8"><div class="h6 text-left">phoenixgatesol</div></div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-4"><div class="h6 text-right"><FormattedMessage id="contact.home_item_3" /></div></div>
                                        <div class="col-8"><div class="h6 text-left"><a class="mail-to-link" href="mailto:marketing@phoenixgatesolutions.com">marketing@phoenixgatesolutions.com</a></div></div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-4"><div class="h6 text-right"><FormattedMessage id="contact.home_item_4" /></div></div>
                                        <div class="col-8"><div class="h6 text-left">1, Hala Perajurit 6, <br />Taman Perak, <br />31400 Ipoh, <br />Perak, Malaysia.</div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="google-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.90012398728!2d101.12695731511945!3d4.611893396649625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31caed107d47761f%3A0x93c41f692f0133b8!2s1%2C%20Hala%20Perajurit%206%2C%20Taman%20Perak%2C%2031400%20Ipoh%2C%20Perak!5e0!3m2!1sen!2smy!4v1614426606363!5m2!1sen!2smy" width="100%" height="450" allowfullscreen="" loading="lazy" class="border-0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default Contact;